export enum WizardSection {
  DETAILS = 'DETAILS',
  PATIENT = 'PATIENT',
  ORDERING_PROVIDER = 'ORDERING_PROVIDER',
  SERVICING_PROVIDER = 'SERVICING_PROVIDER',
  PCP_PROVIDER = 'PCP_PROVIDER',
  REFERRING_PROVIDER = 'REFERRING_PROVIDER',
  ORDERING_FACILITY = 'ORDERING_FACILITY',
  SERVICING_FACILITY = 'SERVICING_FACILITY',
  ENCOUNTER = 'ENCOUNTER',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
  ADDITIONAL_QUESTIONS = 'ADDITIONAL_QUESTIONS',
  ATTACHMENTS = 'ATTACHMENTS',
  REVIEW = 'REVIEW',
}

export enum SectionStatus {
  MAPPED = 'mapped',
  UNMAPPED = 'unmapped',
  REQUIRED = 'required',
}
// eslint-disable-next-line
export type RequiredFieldMap = Record<string, SectionStatus | any>

export type RequiredSectionsKeys =
  | 'details'
  | 'patient'
  | 'ordering_provider'
  | 'servicing_provider'
  | 'pcp_provider'
  | 'referring_provider'
  | 'ordering_facility'
  | 'servicing_facility'
  | 'encounter'
  | 'attachments'

export type RequiredSection = Record<RequiredSectionsKeys, boolean>
