import { v4 as uuid } from 'uuid'
import { Patient } from '@/types/form/prior-auth/patient'
import { Provider } from '@/types/form/prior-auth/provider'
import { Facility } from '@/types/form/prior-auth/facility'
import { Encounter } from '@/types/form/prior-auth/encounter'
import { SubmissionDetails } from '@/types/form/prior-auth/submission-details'
import { Insurance } from '@/types/form/prior-auth/insurance'
import { Id } from '@/types/form/prior-auth/id'
import * as WizardModels from '../../../models/wizard/fe'
import { Attachment } from '@/types/form/prior-auth/attachment'

export const getInitialProvider = (): Provider => ({
  information: null,
  contact: null,
  address: null,
})

export const getInitialFacility = (): Facility => ({
  information: null,
  contact: null,
  address: null,
  sameAsProvider: false,
})

export const getInitialState = () => {
  return {
    details: null as SubmissionDetails | null,
    patient: null as Patient | null,
    insurances: [
      {
        id: uuid(),
        payerUniqueId: '',
        planUniqueId: '',
        planName: '',
        planState: null,
        memberId: '',
        groupNumber: '',
        primary: true,
      },
    ] as (Insurance & Id)[],
    providers: {
      ordering: getInitialProvider(),
      servicing: getInitialProvider(),
      pcp: getInitialProvider(),
      referring: getInitialProvider(),
    },
    facilities: {
      servicing: getInitialFacility(),
      ordering: getInitialFacility(),
    },
    encounter: null as Encounter | null,
    signedOrder: null as Attachment | null,
    historyAndPhysical: null as Attachment | null,
    miscAttachments: [] as Attachment[],
    additionalInformation: [] as WizardModels.AdditionalInformationProperty[],
    questionnaireResponse: null as any,
  }
}

export const state = getInitialState()

export type SubmissionState = typeof state
